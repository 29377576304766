<template>
  <div class="table_wrapper">

    <!-- <div class="title expand">全国碳市场(大宗交易)</div> -->
     <van-nav-bar
        title="全国碳市场（大宗交易）"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        fixed
      />
    <el-table ref="multipleTable" :data="tableData_Expand"  style="margin-top: 46px;">
      <el-table-column
        prop="current_date"
        label="日期"
        align="left"
        width="80px"
        class-name="current_date"
        header-align="left"
      >
      </el-table-column>
      <el-table-column
        prop="amount"
        label="成交量"
        width="75px"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="total"
        label="成交额"
        width="110px"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="average_price"
        label="均价"
        width="55px"
        align="right"
        header-align="right"
      >
      </el-table-column>
      <el-table-column
        prop="discount_premium"
        label="折溢价"
        width="75px"
        align="right"
        header-align="right"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.discount_premium.startsWith('-')"
            style="color: #07C160"
          >
            {{ scope.row.discount_premium }}
          </span>
          <span v-else-if="/^0.0+%$/.test(scope.row.discount_premium)">
            {{ scope.row.discount_premium }}
          </span>
          <span
            v-else="scope.row.discount_premium.startsWith('+')"
            style="color: #ff2832"
          >
            {{ scope.row.discount_premium }}
          </span>
        </template>
        <!-- <span> {{}} </span> -->
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "TableWrapper",
  data() {
    return {
      pagination: {
        page: 1,
        limit: 20,
        page_count: 0,
      },
      tableData: [],
      tableData_Expand: [],
    };
  },
//   activated() {
//     console.log("activated, Table");
//   //  this.getTableData();
//     // this.getTableDataExpand();
//   },
  created() {
    this.getTableDataExpand();
  },
  methods: {
    async getTableDataExpand() {
      return new Promise(resolve =>{      
        // http://192.168.23.15:8000/article/carbonsalelist
        this.api
          .postFormAPISM(
            {
              ...this.pagination,
            },
            "/article/carbonsalelist"
          )
          .then((res) => {
            console.log("getTableData", res.data);
            this.tableData_Expand = res.data.data.daily_sale_list;
            // this.pagination.page_count = res.data.data.pagination.page_count;
            resolve(this.tableData_Expand[0]);
          })
          .catch((error) => {
            console.log("error", error);
          });
        // this.tableData_Expand = this.$store.state.oneTrade.daily_sale_list;
      })
    },
    onClickLeft(){
    this.$router.back();
    }
  },
};
</script>
<style lang="less">
.table_wrapper {
  // padding: 28px 0 0 0;
  & > .title {
    height: 26px;
    margin: 0 0 10px;
    display: flex;
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #33353c;
    &.expand {
      margin: 30px 0 10px;
    }
  }
  & > .el-table {
    &::before {
      display: none;
    }
    & .el-table__body tr:hover > td.el-table__cell {
      background-color: transparent;
    }
    & th.el-table__cell {
      border-top: 1px solid #c5c5c5;
      border-bottom: 1px solid #c5c5c5;
      color: rgb(51, 51, 51);
      font-size: 12px;
      font-weight: 400;
      padding: 5px 0px;
      & > .cell {
        padding: 0 6px;
      }
      &.percent {
        & > .cell {
          padding: 0 0 0 12px;
        }
      }
      &.amplitude {
        & > .cell {
          padding: 0 6px 0 12px;
        }
      }
    }
    & td.el-table__cell {
      border-bottom: 1px solid #d7d7d7;
      color: rgb(51, 51, 51);
      font-size: 12px;
      font-weight: 400;
      padding: 5px 0px;
      & > .cell {
        // padding: 0;
        padding: 0 6px;
      }
      &.percent {
        & > .cell {
          padding: 0 0 0 12px;
        }
      }
      &.amplitude {
        & > .cell {
          padding: 0 6px 0 12px;
        }
      }
    }
  }
}
</style>
